import { AppConfig } from 'utils/types/appConfig.type';

const config: AppConfig = {
    api: {
        // baseUrl: 'https://api.docsforhealth.org/api/v1',
        baseUrl:  'https://api.docsforhealth.org/api/v1'
    },
};

export default config;
