import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';
import RouteRegistry from './routeRegistry';
import { LocalStorageService } from 'utils/storage/LocalStorageService';

interface ProtectedRouteProps {
    children: ReactNode;
    isAuthRoute?: boolean;
}

const ProtectedRoute = ({ children, isAuthRoute = false }: ProtectedRouteProps) => {

    const localStorageService = LocalStorageService();
    const user = localStorageService.getUser();

    if (user && isAuthRoute) {
        return <Navigate to={RouteRegistry.app.paths.root.path} replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
