import * as Yup from 'yup';

export const validation = {
    firstNameValidation: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'First name should contain only alphabetic characters and spaces')
        .required('First name is required'),
    lastNameValidation: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'First name should contain only alphabetic characters and spaces')
        .required('Last name is required'),
    emailValidation: Yup.string().matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email address'),
};
