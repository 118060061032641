import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './interceptors';

const baseQuery = baseQueryWithReAuth;

export const globalsAPI = createApi({
    reducerPath: '/globalsApi',
    baseQuery,
    tagTypes: ['Globals'],
    endpoints: (builder) => ({
        getPrimaryLanguage: builder.query({
            query: () => ({
                url: `/globals/primary-language`,
                method: 'GET',
            }),
        }),
        getRace: builder.query({
            query: () => ({
                url: `/globals/race`,
                method: 'GET',
            }),
        }),
        getEducation: builder.query({
            query: () => ({
                url: `/globals/education`,
                method: 'GET',
            }),
        }),
        getRelationshipStatus: builder.query({
            query: () => ({
                url: `/globals/relationship-status`,
                method: 'GET',
            }),
        }),
        getEthnicity: builder.query({
            query: () => ({
                url: `/globals/ethnicity`,
                method: 'GET',
            }),
        }),
        getGender: builder.query({
            query: () => ({
                url: `/globals/gender`,
                method: 'GET',
            }),
        }),
        getHousingStatus: builder.query({
            query: () => ({
                url: `/globals/housing-status`,
                method: 'GET',
            }),
        }),
        getMailingAddress: builder.query({
            query: () => ({
                url: `/globals/mailing-address`,
                method: 'GET',
            }),
        }),
        getResourceTypes: builder.query({
            query: () => ({
                url: `/globals/resource-types`,
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useGetPrimaryLanguageQuery,
    useGetRaceQuery,
    useGetEducationQuery,
    useGetRelationshipStatusQuery,
    useGetEthnicityQuery,
    useGetGenderQuery,
    useGetHousingStatusQuery,
    useGetMailingAddressQuery,
    useGetResourceTypesQuery,
} = globalsAPI;
